.navBarContainer {
  height: 75px;
  padding: 21px 29px 14px 30px;
  background-color: var(--color-red_insitu);
}
.container {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--color-red_insitu);
  display: flex;
  justify-content: space-between;
}

.notiHead {
  width: 350px;
  height: 75px;
  padding: 15px 142px 16px 20px;
  background-color: var(--gris-fondo-menu-y-textos);
}

.notiHead h6 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  font-family: Montserrat-Regular, sans-serif;
  color: #ffffff;
}

.notiHead a {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--verde-topbar-y-botones);
  font-family: Montserrat-Regular, sans-serif;
  margin: 4px 17px 0 0;
}

.clearAll {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--verde-topbar-y-botones);
  font-family: Montserrat-Regular, sans-serif;
  margin: 4px 17px 0 0;
}

.chatHeader {
  height: 92px;
  padding: 19px 19px 20px 20px;
  background-color: var(--gris-fondo-menu-y-textos);
}

.chatInput {
  height: 60px;
  padding: 17px 325px 16px 13px;
  border-radius: 5px;
  border: solid 1px #d0d6dc;
  background-color: #f5f7fa;
}

.chatNew {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  padding: 2px 0;
  text-align: center;
  background: var(--verde-topbar-y-botones);
}

.userProfile {
  width: 337px;
  padding: 16px 100px 16px 15px;
  border-radius: 5px;
  background-color: var(--gris-fondo-menu-y-textos);
}

.drawerLogo {
  margin: 37px 50px 10px 27px;
}

.drawerLogoMobile {
  margin: 5px 50px 10px 27px;
}

.iconCollapse {
  width: 13px;
  margin: 0 5px 17px 7px;
}
.textMenu span {
  font-size: 18px !important;
  font-family: Montserrat-Regular, sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 20px;
  color: var(--color-white);
}

.userProfileAvatar {
  width: 38px;
  height: 38px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.userProfile span {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--color-white);
  font-family: Montserrat-Regular, sans-serif;
}

.logout {
  margin-left: 70px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}

.logout:hover {
  color: #a9ba37;
}

.timeChat {
  color: #a9ba37;
}

.header {
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.icon {
  cursor: pointer;
  width: 25px;
}

.caremeIcon {
  width: 138px;
  height: 40px;
  margin: 0 979px 0 58px;
  object-fit: contain;
}

.iconInactive {
  object-fit: contain;
}

.filterBlueTheme {
  object-fit: contain;
  filter: invert(35%) sepia(100%) saturate(359%) hue-rotate(163deg) brightness(95%) contrast(96%);
}

.filterOrangeTheme {
  object-fit: contain;
  filter: invert(69%) sepia(66%) saturate(4927%) hue-rotate(339deg) brightness(91%) contrast(92%);
}

.filterRedTheme {
  object-fit: contain;
  filter: invert(30%) sepia(23%) saturate(4241%) hue-rotate(338deg) brightness(109%) contrast(86%);
}

.filterGrayTheme {
  object-fit: contain;
  filter: invert(75%) sepia(5%) saturate(621%) hue-rotate(156deg) brightness(98%) contrast(89%);
}

.menuLabel {
  margin-left: 20px;
}

.iconActive {
  object-fit: contain;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  height: 58px;
  padding: 12px 22px 11px !important;
  margin: 0 0 16px !important;
  border-radius: 12px !important;
}

.iconContainerActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  height: 58px;
  border-radius: 12px !important;
  padding: 12px 22px 11px !important;
  margin: 0 0 16px !important;
  background-color: var(--color-orange-theme) !important;
}

.iconMenu {
  width: 24px;
  height: 20px;
  margin-bottom: 8px;
  margin-left: 10px;
  object-fit: contain;
}

.iconButton:hover {
  background-color: inherit !important;
}

@media (max-width: 599px) {
  .caremeIcon {
    width: 130px;
    height: 40px;
    margin: 0 979px 0 30px;
    object-fit: contain;
  }

  .iconContainer {
    padding: 15px !important;
  }
  .iconContainerActive {
    padding: 15px !important;
  }
}

@media (max-width: 500px) {
  .caremeIcon {
    width: 110px;
    height: 40px;
  }

  .navRightContainer button {
    padding: 8px !important;
    margin: 0 !important;
  }
}
